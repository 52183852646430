import { graphql } from "gatsby"
import { OurGrantsPage } from "@pageTemplates"

export default OurGrantsPage

export const pageQuery = graphql`
  query Grants {
    ourGrantProcessJson {
      hero {
        preTitle
        preDescription
        title
        description
        image {
          src {
            childImageSharp {
              gatsbyImageData(width: 2000, layout: FULL_WIDTH)
            }
            publicURL
          }
          alt
        }
      }
      charitiesList {
        coloured
        values {
          anchorLinkId
          image {
            src {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
            alt
          }
          icon {
            src {
              publicURL
            }
            alt
          }
          title
          description
        }
      }
      criteria {
        title
        criteria {
          icon {
            src {
              publicURL
            }
            alt
          }
          description
        }
      }
      howToBeConsidered {
        title
        description
        submission {
          title
          description
          notice
          callToAction {
            content
            linkLocation
          }
        }
      }
      exclusions {
        title
        icon {
          src {
            publicURL
          }
          alt
        }
        list {
          listItem
        }
      }
      fields {
        slug
      }
    }
  }
`
